<template>
  <v-container fluid style="height: 100%; display: flex; flex-direction: column">
    <page-title title="Restore Projects" :badge="this.total" />
    <v-row class="mt-0 mb-1" justify="space-between" align="center">
      <v-col
        md="12"
        sm="12"
        cols="12"
        class="d-flex flex-row align-center justify-end flex-wrap"
        style="gap: 0.5rem"
      >
        <filter-manager
          ref="filterManager"
          v-model="selectedFilters"
          :options.sync="options"
        ></filter-manager>
        <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet>
        <columns-visibility-control
          :defaults="defaultHeaders"
          :withColumnsOrder="true"
          v-model="headers"
          :storageKey="storageKey"
        />
        <v-sheet elevation="2" height="32" rounded class="d-inline-flex" v-if="false">
          <v-btn-toggle v-model="tableMode" mandatory color="info" dense group>
            <v-btn :value="'list'" color="white" style="border-radius: 4px">
              <v-icon>fal fa-list</v-icon>
            </v-btn>
            <v-btn :value="'cards'" color="white" style="border-radius: 4px">
              <v-icon>fal fa-folders</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-sheet>
        <v-text-field
          v-model="options.search"
          label="Search"
          ref="mainSearch"
          class="table-search-field"
          @focus="mainSearchInFocus = true"
          @blur="mainSearchInFocus = false"
          :style="{
            'max-width':
              mainSearchInFocus || (options.search != '' && options.search != null)
                ? '200px'
                : '110px',
          }"
          dense
          solo
          hide-details
          clearable
          prepend-inner-icon="far fa-search"
          :loading="loadingStates.table"
        >
        </v-text-field>
        <refresh :loading="loadingStates.table" @refresh="getDataDebounced()"></refresh>
      </v-col>
    </v-row>
    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      v-if="tableMode === 'list'"
      dense
      :headers="tableHeader"
      :items="entities"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page="options.itemsPerPage"
      :loading="loadingStates.table"
      class="elevation-2 projects-table table-sticky-header-exclude-footer"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:progress>
        <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
      </template>

      <template v-slot:[`header.jiraCode`]="{ header }">
        <i :class="header.icon"></i>
        {{ header.text.toUpperCase() }}
      </template>

      <template v-slot:[`item.jiraCode`]="{ item }">
        <a
          v-if="item.jiraCode != null && item.jiraCode != ''"
          target="_blank"
          class="jira-external-link"
          @click.stop
          :href="'https://dangeloconsultants.atlassian.net/browse/' + item.jiraCode"
        >
          <span class="icon-wrpr mr-1">
            <i class="fab fa-jira org-icon"></i>
            <i class="far fa-external-link alt-icon"></i>
          </span>
          <span>{{ item.jiraCode }}</span>
        </a>
        <span v-else class="text--disabled">
          <i class="fad fa-unlink"></i>
        </span>
      </template>

      <template v-slot:[`item.client`]="{ item }">
        <v-tooltip top nudge-top="-16px">
          <template v-slot:activator="{ on, attrs }">
            <div class="pa-2" v-bind="attrs" v-on="on" @click.stop="viewClient(item.client.id)">
              <v-img
                v-if="item.client.logoUrl != null && item.client.logoUrl != ''"
                :src="item.client.logoUrl"
                max-height="28"
                max-width="54"
                position="left center"
                contain
              >
              </v-img>
              <v-img
                v-else
                src="/img/DNA_Symbol.png"
                max-height="28"
                max-width="54"
                position="left center"
                contain
              >
              </v-img>
            </div>
          </template>
          <span>{{ item.client.name }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <h4>{{ item.fullName }}</h4>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <project-status :status="item.status" small show-parent></project-status>
      </template>

      <template v-slot:[`item.createDate`]="{ item }">
        <dater :date="item.createDate" dateonly></dater>
      </template>

      <template v-slot:[`item.updateDate`]="{ item }">
        <dater :date="item.updateDate" dateonly></dater>
      </template>

      <template v-slot:[`item.spaceCount`]="{ item }">
        <space-count :count="item.spaceCount" />
      </template>

      <template v-slot:[`item.equipmentCount`]="{ item }">
        <equipment-count :count="item.equipmentCount" />
      </template>

      <template v-slot:[`item.projectComplexity`]="{ item }">
        <span
          class="font-weight-medium"
          :class="item.projectComplexity > 0 ? 'secondary--text' : 'text--disabled'"
        >
          {{ item.projectComplexity }}
        </span>
      </template>

      <template v-slot:[`item.totalMsrpPrice`]="{ item }">
        <div class="project-total">
          <v-tooltip left z-index="999" nudge-left="-4px">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="d-inline-block"
                style="font-size: 14px; font-weight: 600 !important"
              >
                {{ totalMSRPWithInstallCost(item) | usdFormat }}
              </div>
            </template>
            <span>{{ item.name }}</span>
            <v-divider class="my-1 d-block" style="width: 100%" dark></v-divider>
            <span class="d-flex flex-column align-end" style="gap: 0.25rem">
              <span style="font-size: 12px; font-weight: 500 !important">
                <b class="blue--text mr-1" style="font-size: 12px; font-weight: 700 !important">
                  {{ installationFactor(item) }}x
                </b>
                {{ installationCost(item) | usdFormat }}
                <i class="fad fa-wrench ml-1"></i>
              </span>
              <span>
                {{ item.totalMsrpPrice | usdFormat }}
                <i class="fad fa-usd-square ml-1"></i>
              </span>
              <v-divider
                class="my-1 d-block"
                style="width: 100%; border-style: dashed !important"
                dark
              ></v-divider>
              <span style="font-size: 16px; font-weight: 600 !important">
                {{ totalMSRPWithInstallCost(item) | usdFormat }}
              </span>
            </span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="secondary" small dark @click.stop="restore(item.id, item.name)">
          <i class="fas fa-trash-arrow-up mr-2"></i>
          Restore
        </v-btn>
      </template>

      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>
    <v-data-iterator
      v-if="tableMode === 'cards'"
      :items="entities"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page="options.itemsPerPage"
      :loading="loadingStates.table"
    >
      <template v-slot:default="props">
        <v-row>
          <v-col v-for="item in props.items" :key="item.id" sm="12" md="6" lg="3">
            <project-card :project="item"></project-card>
          </v-col>
        </v-row>
      </template>
      <template v-slot:no-data>
        <div class="text-center">
          <img width="500" src="/img/art/fogg-no-connection-2.png" style="border-radius: 1rem" />
          <p class="font-weight-bold">No Data Available!</p>
        </div>
      </template>
      <template v-slot:loading>
        <v-row justify="center">
          <v-col sm="12" md="6" lg="4" class="d-flex flex-column text-center">
            <p class="font-weight-bold">Loading... Please wait</p>
            <v-progress-linear color="info" indeterminate rounded height="6"></v-progress-linear>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <edit-client ref="editClient"></edit-client>
  </v-container>
</template>

<script>
import perms from "../../../plugins/permissions";
import ApiService from "../services/projects-service.js";
import ProjectStatus from "../components/ProjectStatus.vue";
import EditClient from "../../Companies/Clients/components/EditClient.vue";
import projectHeader from "../config/tables/projectRestore.header";
import FilterManager from "../../Shared/components/FilterManager/FilterManager.vue";
import {
  UserFilterSettings,
  ClientFilterSettings,
} from "../../Shared/components/FilterManager/FilterSettings";

export default {
  components: { ProjectStatus, EditClient, FilterManager },
  data() {
    return {
      perms: perms,
      selectedFilters: [],
      storageKey: "Projects",
      tableMode: "list",
      entities: [],
      selected: {},
      total: 0,
      projectsTimerId: -1,
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
        sortBy: ["updateDate"],
        sortDesc: [true],
        ClientId: [],
        Status: [],
        createdByIds: [],
        updatedByIds: [],
      },
      loadingStates: {
        table: false,
      },
      defaultHeaders: [],
      headers: projectHeader,
    };
  },
  props: {},
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.$route.query.pq) this.options.search = this.$route.query.pq;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    this.initFilterManagerSettings();
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
    this.$log("mounted", this.$route.query.pq);
    window.addEventListener("popstate", this.checkRouteQuery, false);
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
    window.removeEventListener("popstate", this.checkRouteQuery);
  },
  methods: {
    initFilterManagerSettings() {
      var createdByFilterSettings = new UserFilterSettings();
      createdByFilterSettings.title = "Created By";
      createdByFilterSettings.model = "createdByIds";
      createdByFilterSettings.ref = "createdByFilter";

      var updatedByFilterSettings = new UserFilterSettings();
      updatedByFilterSettings.title = "Updated By";
      updatedByFilterSettings.model = "updatedByIds";
      updatedByFilterSettings.ref = "updatedByFilter";

      var clientFilterSettings = new ClientFilterSettings();

      this.selectedFilters = [
        createdByFilterSettings,
        updatedByFilterSettings,
        clientFilterSettings,
      ];
    },
    installationFactor(project) {
      return project.installationFactor || 0.25;
    },
    installationCost(project) {
      return this.installationFactor(project) * project.totalMsrpPrice;
    },
    totalMSRPWithInstallCost(project) {
      return project.totalMsrpPrice + this.installationCost(project);
    },
    viewClient(id) {
      this.$refs.editClient.open(id);
    },
    getDataDebounced() {
      if (this.projectsTimerId == -1) {
        this.projectsTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.projectsTimerId);

      // delay new call 400ms
      this.projectsTimerId = setTimeout(() => {
        this.getData();
      }, 400);
    },
    getData() {
      this.loadingStates.table = true;
      this.options.GetDeletedOnly = true;
      this.$log("getData()", this.$clean(this.options, true));
      this.$backToTop(0, document.querySelector(".projects-table .v-data-table__wrapper"));
      ApiService.query(this.$clean(this.options, true))
        .then((resp) => {
          this.entities = resp.data.items;
          this.$log("this.entities", this.entities);
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    checkRouteQuery(evt) {
      this.$log("checkRouteQuery", this.$route.query.pq, evt);
      if (this.$route.query.pq) this.options.search = this.$route.query.pq;
      else this.options.search = null;
    },
    restore(id, name) {
      this.$dialog
        .warning({
          text: `Are you sure you want to Restore this Project: <b>${name}</b>?`,
          title: `Restore Project?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return ApiService.restore(id)
                  .then((resp) => {
                    this.$router.push({
                      name: `view-project`,
                      params: { id: id },
                    });
                  })
                  .catch((err) => {
                    this.$handleError(err);
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getDataDebounced();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.projects-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}

.v-list.project-status-filter {
  .v-list-item:not(:last-child) {
    border-bottom: thin solid rgba($shades-black, 0.1);
  }
}
</style>
